import bodrogului18 from './assets/puncte/bodrogului-18.jpeg';
import bodrogului20 from './assets/puncte/bodrogului-20.png';
import vladimirescu from './assets/puncte/validimirescu.jpeg';

const Data = {
  contact: {
    company: 'SC Nick ITP Rapid SRL',
    email: 'pistol.claudiu@itp-truck.ro',
    phone: '0725232295',
    adress: 'Calea Bodrogului, nr. 18, Arad',
    adressHint: '(coborarea Aeroport Arad de pe Autostrada A1)',
    gpsLat: 46.1713161,
    gpsLong: 21.2820332,
    cui: '39106962',
    nrc: 'J2/497/2019',
    nrRar: '',
    facebook: 'https://www.facebook.com/itptruckarad/',
    site: 'www.itp-truck.ro',
    googleMapsSrc:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5525.933655087531!2d21.282033155073506!3d46.171316054511976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4745993d8265d5f3%3A0x74f5e32c6dcf9941!2sITP%20Truck%20Arad!5e0!3m2!1sro!2sro!4v1647521993248!5m2!1sro!2sro',
  },
  copyright: 'ITP Truck Arad. Toate drepturile rezervate.',
  NAV_ITEMS: [
    {
      label: 'Home',
      href: '/',
    },
    {
      label: 'Servicii',
      children: [
        {
          label: 'Verifica valabilitate ITP',
          // subLabel: 'valabilitate ITP',
          href: '/servicii',
        },
        {
          label: 'Acte necesare ITP',
          // subLabel: 'necesare ITP',
          href: '/servicii',
        },
        {
          label: 'Operatiuni ITP',
          href: '/servicii',
        },
        {
          label: 'Verificare tahograf',
          href: '/servicii',
        },
      ],
      href: '/servicii',
    },
    {
      label: 'Preturi',
      href: '/preturi',
    },
    {
      label: 'Programare online',
      href: '/programare-online',
    },
    {
      label: 'Puncte de lucru',
      href: '/puncte-de-lucru',
    },
    {
      label: 'Contact',
      href: '/contact',
    },
  ],
};

export const puncteLucru = [
  {
    name: 'Arad',
    address: 'Calea Bodrogului nr. 18',
    phone: '0752273815',
    image: bodrogului18,
  },
  {
    name: 'Arad',
    address: 'Calea Bodrogului nr. 20',
    phone: '0725232295',
    image: bodrogului20,
  },
  {
    name: 'Vladimirescu',
    address: 'Str. Berna nr. 2',
    phone: '0752275053',
    image: vladimirescu,
  },
];

export { Data };
