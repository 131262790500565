import { Box, Button, Container, Flex, Heading, Image, SimpleGrid, Stack, Text, useTheme } from '@chakra-ui/react';
import { Fragment } from 'react';
import Iframe from 'react-iframe';
import { Data, puncteLucru } from './Data';
import Seo from './components/Seo';
import Titlebar from './components/Titlebar';

const Contact = () => {
  const theme = useTheme();
  const { contact } = Data;

  return (
    <>
      <Seo siteTitle='Contact' canonical='contact' />
      <Titlebar title='Contact' />
      <Box background='secondary.100' pt={{ base: 5, md: 10 }}>
        <Container maxW='5xl' my={{ base: 10, md: 15 }}>
          <Box
            background='white'
            color='secondary.500'
            boxShadow={`-20px 20px ${theme.colors.secondary['200']}`}
            p={{ base: 5, sm: 10, md: 10, lg: 16 }}
            mb={10}
          >
            <Box p={4}>
              <Heading as='h2' mb={5} fontSize='2xl' fontWeight={600}>
                {contact.company}
              </Heading>
              <Item label='CUI' value={contact.cui} />
              <Item label='Nr. Registrul Comertului' value={contact.nrc} />
              <Item label='Nr. Autorizatie RAR' value={contact.nrRar} />
              <Item
                label='Coordonate GPS'
                value={`Lat: ${contact.gpsLat} | Log:
                  ${contact.gpsLong}`}
              />
              <Item label='Telefon' value={contact.phone} />
              <Item label='Email' value={contact.email} />

              <Heading as='h3' size='md' fontWeight='semibold' textAlign='center' pb={8} pt={12}>
                Puncte de lucru
              </Heading>
              <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
                {puncteLucru.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <PunctItem name={item.name} address={item.address} image={item.image} phone={item.phone} />
                    </Fragment>
                  );
                })}
              </SimpleGrid>
            </Box>
          </Box>
        </Container>
        <Box bg='white' borderTopWidth={4} borderTopColor='secondary.200' mt={24}>
          <Iframe src={contact.googleMapsSrc} width='100%' height='650' allowfullscreen loading='lazy'></Iframe>
        </Box>
      </Box>
    </>
  );
};

const Item = ({ label, value }) => {
  return (
    <>
      <Text fontWeight={600} fontSize={{ base: 'lg', sm: 'lg' }} pb={3}>
        {label}
        {': '}
        <Text as={'span'} fontWeight={400}>
          {value}
        </Text>
      </Text>
    </>
  );
};

const PunctItem = ({ address, image, phone, name }) => {
  return (
    <Stack align='center'>
      <Flex w='150px' h='150px' align={'center'} justify={'center'} color={'white'} rounded={'full'} bg={'gray.100'} mb={1}>
        <Image borderRadius='full' boxSize='150px' width='150px' height='150px' src={image} alt={name} />
      </Flex>
      <Text fontWeight={600}>{name}</Text>
      <Text color={'gray.600'}>{address}</Text>
      <Button colorScheme='primary' variant='ghost' href={`tel:${phone}`} as={'a'}>
        {phone}
      </Button>
    </Stack>
  );
};

export default Contact;
