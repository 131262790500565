import { extendTheme } from '@chakra-ui/react';

const colors = {
  primary: {
    50: '#faf6f6',
    100: '#eddcdc',
    200: '#ddbebd',
    300: '#ca9a98',
    400: '#c08582',
    500: '#b16966',
    600: '#a4504c',
    700: '#95332e',
    800: '#812924',
    900: '#5e1e1a',
  },
  secondary: {
    50: '#faf9f9',
    100: '#f2f1f1',
    200: '#e8e7e7',
    300: '#d5d4d3',
    400: '#afacaa',
    500: '#827e7b',
    600: '#585450',
    700: '#3b3631',
    800: '#241f1b',
    900: '#1d1916',
  },
};

const fonts = {
  body: 'Open Sans, sans-serif',
  heading: 'Noto Serif Display, serif',
};

const globalStyles = {
  styles: {
    global: {
      body: {
        color: 'secondary.700',
      },
      '*:focus': {
        outline: '0 !important',
        boxShadow: 'none !important',
      },
      a: {},

      // react-datepicker
      '.react-datepicker__header': {
        background: 'secondary.100',
      },
      '.react-datepicker__day--keyboard-selected': {
        background: 'primary.500',
        color: 'white',
      },
      '.react-datepicker__day--keyboard-selected:hover': {
        background: 'primary.700',
      },
      '.react-datepicker__day:hover': {
        background: 'primary.100',
      },
      '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected':
        {
          background: 'primary.700',
        },
      '.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover':
        {
          background: 'primary.100',
        },
      '.react-datepicker__day--selected': {
        background: 'primary.700',
      },
    },
  },
};

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const customTheme = extendTheme({
  colors,
  fonts,
  ...globalStyles,
  config,
});

export default customTheme;
