import FormProgramare from './components/FormProgramare';

const Programare = () => {
  return (
    <>
      <FormProgramare />
    </>
  );
};

export default Programare;
