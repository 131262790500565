import { Box, Heading } from '@chakra-ui/react';

const Titlebar = ({ title, subtitle }) => {
  return (
    <Box textAlign='center' py={10} background='secondary.50'>
      <Heading as='h2' size='xl'>
        {title}
      </Heading>
      {subtitle && (
        <Heading as='h4' size='sm'>
          {subtitle}
        </Heading>
      )}
    </Box>
  );
};

export default Titlebar;
