import { Helmet, HelmetProvider } from 'react-helmet-async';

const Seo = ({ siteTitle, canonical }) => {
  return (
    <Helmet>
      <title>{`ITP Truck Arad - ${siteTitle}`}</title>
      <link rel='canonical' href={`http://itp-truck.ro/${canonical}`} />
    </Helmet>
  );
};

export default Seo;
