import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Contact from './Contact';
import Home from './Home';
import NotFound from './NotFound';
import Price from './Price';
import Programare from './Programare';
import Puncte from './Puncte';
import Servicii from './Servicii';
import CookieNotification from './components/CookieNotification';
import Footer from './components/Footer';
import Navigation from './components/Navigation';

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Navigation />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='servicii' element={<Servicii />} />
        <Route path='preturi' element={<Price />} />
        <Route path='programare-online' element={<Programare />} />
        <Route path='contact' element={<Contact />} />
        <Route path='puncte-de-lucru' element={<Puncte />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
      <CookieNotification />
      <Footer />
    </BrowserRouter>
  );
};

export default AppRoutes;
