import { Box, Container, Heading, Link, ListItem, OrderedList, Stack, Text, UnorderedList } from '@chakra-ui/react';
import Seo from './components/Seo';
import Titlebar from './components/Titlebar';

const Servicii = () => {
  return (
    <>
      <Seo siteTitle='Servicii' canonical='servicii' />
      <Titlebar title='Servicii' />
      <Stack>
        <Box>
          <Container maxW={'3xl'}>
            <Box py={10}>
              <Heading fontWeight={700} fontSize={{ base: 'xl', sm: 'xl', md: '2xl' }} pb={5}>
                ITP (Inspectia Tehnica Periodica)
              </Heading>
              <Text maxW={'4xl'} fontSize='xl' fontWeight={300}>
                Este o verificare tehnica a autovehiculului care atesta capacitatea acestuia de a participa in conditii de siguranta la trafic. Timpul
                dumneavoastra este pretios, ITP-ul este obligatoriu - iar lipsa lui se sanctioneaza cu puncte penalizare, puncte amenda si retinerea
                certificatului de inmatriculare, verificarea ulterioara a autovehiculului in vederea redobandirii talonului efectuandu-se numai la R.A.R. Echipa
                noastra va vine in ajutor cu profesionalism si promptitudine efectuand verificari ITP.
              </Text>
            </Box>
          </Container>
        </Box>

        <Box bg='secondary.100' py={10} mb={10} id='verifica-valabilitate-itp'>
          <Container maxW={'3xl'}>
            <Box>
              <Heading fontWeight={700} fontSize={{ base: 'xl', sm: 'xl', md: '2xl' }} pb={5}>
                Verifica valabilitate ITP
              </Heading>
              <Text maxW={'4xl'} fontSize='xl' fontWeight={300} mb={5}>
                Nu stii cand expira ITP-ul? Verificare Valabilitate ITP – Verifica la RAR daca Inspectia Tehnica Periodica a masinii tale este valabila!
              </Text>

              <Text maxW={'4xl'} fontSize='xl' fontWeight={300} mb={5}>
                Registrul Auto Roman ofera posibilitatea de a verifica daca inspectia tehnica periodica a masinii dumneavoastra este valabila – daca a fost
                inregistrata in baza de date.
              </Text>

              <Text maxW={'4xl'} fontSize='xl' fontWeight={300} mb={5}>
                Daca sunteti sigur ca ITP-ul a fost corect efectuat intr-o statie autorizata de R.A.R. pentru aceasta activitate si totusi nu va regasiti in
                baza de date, completati un formular de sesizare. Pentru a evita astfel de situatii, nu lasati diferite persoane binevoitoare sa va "rezolve"
                aceasta problema (de cele mai multe ori aceste ITP-uri sunt false), cel mai bine mergeti personal la o statie ITP autorizata, ocazie cu care
                veti afla si daca masina are eventuale probleme.
              </Text>

              <Text maxW={'4xl'} fontSize='xl' fontWeight={300} mb={5}>
                PENTRU A CONSULTA BAZA DE DATE A R.A.R.{' '}
                <Link href='http://prog.rarom.ro/rarpol/rarpol.asp' isExternal color='primary.500' sx={{ textDecoration: 'underline' }}>
                  click AICI.
                </Link>{' '}
              </Text>
              <Text maxW={'4xl'} fontSize='xl' fontWeight={300} mb={5}>
                Obligatia efectuarii inspectiilor tehnice periodice precum şi a respectarii periodicitatii acestora in functie de categoria vehiculului, revine
                detinatorului legal al acestuia.
              </Text>

              <Text maxW={'4xl'} fontSize='xl' fontWeight={300} mb={5}>
                Termenul pentru efectuarea primei inspectii tehnice periodice se socoteşte de la data primei imatriculari, iar pentru urmatoarea inspectie
                tehnica periodica, de la data celei precedente.
              </Text>

              <Text maxW={'4xl'} fontSize='xl' fontWeight={300} mb={5}>
                Vehiculele noi, inmatriculate pentru prima data in Romania, nu se regasesc pe link-ul "Verificare ITP" deoarece acestea nu au fost supuse unei
                inspectii tehnice periodice.
              </Text>

              <Text maxW={'4xl'} fontSize='xl' fontWeight={300}>
                Actualizarea bazelor de date ce furnizeaza informatiile disponibile pe site se efectueaza periodic. Bazele de date ale R.A.R. ce cuprind
                vehiculele cu inspectia tehnica periodica in termen de valabilitate sunt actualizate la un interval mult mai scurt de timp.
              </Text>
            </Box>
          </Container>
        </Box>

        <Box id='acte-necesare-itp'>
          <Container maxW={'3xl'}>
            <Box py={10}>
              <Heading fontWeight={700} fontSize={{ base: 'xl', sm: 'xl', md: '2xl' }} pb={5}>
                Acte necesare ITP
              </Heading>
              <OrderedList fontSize='lg' fontWeight={300}>
                <ListItem> talon autovehicul; </ListItem>
                <ListItem>
                  {' '}
                  Certificat de inmatriculare al autovehiculului (talon) – original, sau dupa caz, autorizatia provizorie de circulatie buletin identitate;
                </ListItem>
                <ListItem>buletinul de identitate al conducatorului auto – original asigurare RCA;</ListItem>
                <ListItem>polita R.C.A. (Asigurare de Raspundere Civila Auto) valabila la data inspectiei – original carte identitate autovehicul;</ListItem>
                <ListItem>
                  cartea de identitate a autovehiculului (CIV) – original – securizata de R.A.R Daca proprietarul este o societate de leasing se accepta copie
                  dupa cartea de identitate a autovehiculului stampilata de proprietar cu mentiunea " conform cu originalul". Pentru nelamuriri sau detalii
                  suplimentare va rugam sa luati legatura cu inspectorii de la statie, personalul nostru poate fi contactat zilnic intre orele 8.30 – 19.30 in
                  zilele lucratoare.
                </ListItem>
              </OrderedList>
            </Box>
          </Container>
        </Box>

        <Box bg='secondary.100' id='operatiuni-itp'>
          <Container maxW={'3xl'}>
            <Box py={10}>
              <Heading fontWeight={700} fontSize={{ base: 'xl', sm: 'xl', md: '2xl' }} pb={5}>
                Operatiuni ITP
              </Heading>

              <UnorderedList fontSize='lg' fontWeight={300}>
                <ListItem>identificare autovehicul (verificare serii caroserie si motor);</ListItem>
                <ListItem>verificare aspect general (fara urme accident, echiparea conform cu CIV, etc);</ListItem>
                <ListItem>verificare frane;</ListItem>
                <ListItem>noxe;</ListItem>
                <ListItem>faruri + instalatie electrica;</ListItem>
                <ListItem>verificare jocuri;</ListItem>
                <ListItem>fotografiere finala (dupa caz);</ListItem>
                <ListItem>introducere date in calculator;</ListItem>
                <ListItem>completare c.i. in anexa;</ListItem>
                <ListItem>eliberarea documentelor autovehiculului;</ListItem>
                <ListItem>fotografiere autovehicul.</ListItem>
              </UnorderedList>
            </Box>
          </Container>
        </Box>

        <Box id='verificare-tahograf'>
          <Container maxW={'3xl'}>
            <Box py={10}>
              <Heading fontWeight={700} fontSize={{ base: 'xl', sm: 'xl', md: '2xl' }} pb={5}>
                Verificare tahograf
              </Heading>
              <Text maxW={'4xl'} fontSize='xl' fontWeight={300} mb={5}>
                Tahografele sunt aparate de masura si control care inregistreaza km efectuati de autovehiculele comerciale si activitatea soferilor.
              </Text>

              <Text maxW={'4xl'} fontSize='xl' fontWeight={300} mb={2}>
                Noi iti oferim urmatoarele servicii:
              </Text>

              <UnorderedList fontSize='lg' fontWeight={300}>
                <ListItem>Eliberare dovada de verificare pentru orice tip de tahograf, valabila 2 ani;</ListItem>
                <ListItem>Verificare orice tip de tahograf si limitator de viteza;</ListItem>
                <ListItem>Calibrare tahografe;</ListItem>
                <ListItem>Montare si comercializare tahografe digitale;</ListItem>
                <ListItem>Montare si comercializare limitatoare de viteza;</ListItem>
                <ListItem>Descarcarea si interpretarea datelor din cartela soferului si din tahograful digital;</ListItem>
                <ListItem>Instruire privind utilizarea tahografului digital;</ListItem>
                <ListItem>Montare placute reflectorizante si banda reflectorizanta;</ListItem>
                <ListItem>Vanzari de diagrame si accesorii tahografe.</ListItem>
              </UnorderedList>
            </Box>
          </Container>
        </Box>
      </Stack>
    </>
  );
};

export default Servicii;
