import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import CookieConsent from 'react-cookie-consent';
import { Data } from './../Data';
import { useRef } from 'react';

const CookieNotification = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const theme = useTheme();
  const btnRef = useRef();

  const GdprContent = () => {
    return (
      <>
        <Modal
          onClose={onClose}
          finalFocusRef={btnRef}
          isOpen={isOpen}
          scrollBehavior='outside'
          size='lg'
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Despre cookies</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text mb={4}>
                Site-ul {Data.contact.site} foloseste cookie-uri proprii
                (first-party cookie) si de la terti (third-party cookie) pentru
                a furniza vizitatorilor o experienta de navigare superioara si
                servicii adaptate nevoilor specifice ale acestora.
              </Text>
              <Text pb={4}>
                Cu ajutorul fisierelor de tip cookie, detinatorul website-ului
                poate imbunatati interactiuniea utilizatorilor cu website-ul.
              </Text>

              <Text pb={4}>
                Acestea sunt fisiere de mici dimensiuni, formate din litere si
                numere, care pot fi stocate pe computerul, terminalul mobil sau
                alte echipamente ale unui utilizator de pe care se acceseaza
                Internetul. Fisierele de tip cookie ajuta la servicii web mai
                bune si au ca rezultat navigarea prietenoasa si personalizata a
                fiecarui utilizator.
              </Text>

              <Text pb={4}>
                Fisierele de tip cookie NU sunt virusi! Nu sunt alcatuite din
                bucati de cod, asa ca nu pot fi executate, nici nu pot auto-rula
                pe sistemul tau de operare.
              </Text>

              <Text>
                In cazul in care doriti sa eliminati fisierele de tip cookie
                stocate pe dispozitivele dvs. si sa configurati browserul dvs.
                web astfel incât sa refuze fisiere de tip cookie, puteti sa
                utilizati setarile privind preferintele din browser-ul dvs. web.
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme='primary' mr={3} onClick={onClose}>
                Inchide
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };
  return (
    <>
      <CookieConsent
        location='bottom'
        buttonText='Am inteles'
        cookieName='itptruckGdpr'
        style={{
          background: theme.colors.whiteAlpha['900'],
          color: theme.colors.secondary['500'],
          padding: theme.space['2'],
        }}
        buttonStyle={{
          color: 'white',
          background: theme.colors.primary['500'],
          padding: '9px 18px',
          fontSize: theme.fontSizes.sm,
          borderRadius: theme.radii.md,
          fontWeight: 500,
        }}
        expires={150}
      >
        <Flex
          alignItems={{ base: 'start', sm: 'center' }}
          direction={{ base: 'column', sm: 'row' }}
        >
          <Text>
            Acest website foloseste cookies pentru a asigura o experienta optima
            de utilizare.
            <Button onClick={onOpen} ref={btnRef} variant='link' pl={5}>
              Detalii
            </Button>
          </Text>

          <GdprContent />
        </Flex>
      </CookieConsent>
    </>
  );
};

export default CookieNotification;
