import {
  Box,
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Switch,
  useTheme,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import ro from 'date-fns/locale/ro';
import { useEffect, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import { MdEmail, MdPerson, MdPhone } from 'react-icons/md';
import Seo from './Seo';
import Titlebar from './Titlebar';

const FormProgramare = () => {
  registerLocale('ro', ro);
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const toast = useToast();
  const theme = useTheme();

  const initialFormState = {
    name: '',
    email: '',
    phone: '',
    confirmation: false,
    gdpr: false,
    puncteLucru: '',
  };

  const [formData, setFormData] = useState(initialFormState);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);

  useEffect(() => {
    if (isSuccessfullySubmitted) {
      toast({
        position: 'bottom-right',
        status: 'success',
        duration: 3000,
        isClosable: true,
        title: 'Programarea a fost trimis cu succes.',
      });

      setIsSuccessfullySubmitted(false);
    }
  }, [toast, isSuccessfullySubmitted]);

  const onSubmit = async (data) => {
    data.dateAppointment = data.dateAppointment.toLocaleString();
    console.log('debug', data);
    try {
      const response = await axios({
        method: 'POST',
        url: process.env.REACT_APP_API_PROD,
        data,
      });

      if (response.status === 200 && response.data.sent) {
        reset(initialFormState);
        setIsSuccessfullySubmitted(response.data.sent);
        setFormData(initialFormState);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const messageRequiredField = 'Campul este obligatoriu';

  return (
    <>
      <Seo siteTitle='Programare online' canonical='programare-online' />
      <Titlebar title='Programare online' />
      <Box background='secondary.100' py={{ base: 5, md: 10 }}>
        <Container maxW={'md'} my={{ base: 10, md: 15 }}>
          <Box p={10} background='white' color='secondary.500' boxShadow={`-20px 20px ${theme.colors.secondary['200']}`}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl isInvalid={errors.name} pb='5'>
                <InputGroup>
                  <InputLeftElement children={<MdPerson />} />
                  <Input
                    id='name'
                    placeholder='Nume si Prenume'
                    {...register('name', {
                      required: messageRequiredField,
                      minLength: { value: 4, message: 'Minim 4 caractere' },
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.email} pb='5'>
                <InputGroup>
                  <InputLeftElement children={<MdEmail />} />
                  <Input
                    id='email'
                    placeholder='Email'
                    {...register('email', {
                      required: true,
                      pattern:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                  />
                </InputGroup>

                <FormErrorMessage>{errors.email && 'Email invalid'}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.phone} pb='5'>
                <InputGroup>
                  <InputLeftElement children={<MdPhone />} />
                  <Input
                    id='phone'
                    placeholder='Telefon'
                    {...register('phone', {
                      required: true,
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>{errors.phone && messageRequiredField}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.puncteLucru} pb='5'>
                <InputGroup>
                  <Select placeholder='Puncte de lucru' id='puncteLucru' {...register('puncteLucru', { required: true })}>
                    <option value='ITP Service Arad'>ITP Service Arad - Bodrog 20</option>
                    <option value='ITP Truck'>ITP Truck - Bodrog 18</option>
                    <option value='ITP D&D'>ITP D&D - Vladimirescu</option>
                  </Select>
                </InputGroup>
                <FormErrorMessage>{errors.puncteLucru && messageRequiredField}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.dateAppointment} pb='5'>
                <Controller
                  control={control}
                  name='dateAppointment'
                  rules={{ required: true }}
                  render={({ field }) => (
                    <InputGroup>
                      <InputLeftElement children={<MdEmail />} />
                      <ReactDatePicker
                        locale='ro'
                        selected={field.value}
                        onChange={(e) => field.onChange(e)}
                        placeholderText='Selecteaza data si ora dorita'
                        showTimeSelect
                        timeIntervals={60}
                        filterTime={filterPassedTime}
                        dateFormat='dd-MM-yyyy, HH:mm'
                        className='chakra-input css-8tczqk'
                        autoComplete='off'
                      />
                    </InputGroup>
                  )}
                />
                <FormErrorMessage>{errors.dateAppointment && messageRequiredField}</FormErrorMessage>
              </FormControl>

              <FormControl pb='5' display='flex' alignItems='center'>
                <Controller
                  name='confirmation'
                  type='checkbox'
                  control={control}
                  render={() => (
                    <Switch
                      {...register('confirmation')}
                      id='confirmation'
                      colorScheme='primary'
                      isChecked={formData.confirmation}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          confirmation: !formData.confirmation,
                        })
                      }
                    />
                  )}
                />
                <FormLabel
                  htmlFor='confirmation'
                  fontSize='sm'
                  pl={5}
                  _hover={{
                    cursor: 'pointer',
                  }}
                >
                  Doresc sa fiu contactat telefonic pentru confirmarea programarii
                </FormLabel>
              </FormControl>

              <FormControl pb='5' display='flex' alignItems='center'>
                <Controller
                  name='gdpr'
                  type='checkbox'
                  control={control}
                  render={() => (
                    <Switch
                      {...register('gdpr')}
                      id='gdpr'
                      colorScheme='primary'
                      isChecked={formData.gdpr}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          gdpr: !formData.gdpr,
                        })
                      }
                    />
                  )}
                />
                <FormLabel
                  htmlFor='gdpr'
                  fontSize='sm'
                  pl={5}
                  _hover={{
                    cursor: 'pointer',
                  }}
                >
                  Sunt de acord ca datele mele personale introduse in acest formular sa fie prelucrate de ITP Truck.
                </FormLabel>
              </FormControl>

              <Button mt={4} colorScheme='primary' isLoading={isSubmitting} type='submit'>
                Trimite
              </Button>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default FormProgramare;
