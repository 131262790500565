import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { Box, Button, Collapse, Container, Flex, IconButton, Image, Link, Stack, useBreakpointValue, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { MdEmail, MdPhone } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import logo from './../../assets/logo-itp.svg';
import { Data } from './../../Data';
import DesktopNav from './DesktopNav';
import MobileNav from './MobileNav';

const Navigation = () => {
  const { isOpen, onToggle } = useDisclosure();
  const Logo = () => {
    return (
      <Link to='/' as={NavLink}>
        <Image h={90} src={logo} />
      </Link>
    );
  };

  const ButtonActions = () => {
    const isMobile = useBreakpointValue({ base: true, xl: false });

    return (
      <>
        {isMobile && (
          <>
            <IconButton as='a' colorScheme='primary' icon={<MdEmail />} fontSize='xl' href={`mailto:${Data.contact.email}`} isRound />

            <IconButton as='a' colorScheme='primary' icon={<MdPhone />} fontSize='xl' href={`tel:${Data.contact.phone}`} isRound />
          </>
        )}

        {!isMobile && (
          <>
            <Button
              colorScheme='primary'
              variant='outline'
              leftIcon={<MdEmail />}
              as={'a'}
              fontSize={'sm'}
              fontWeight={400}
              href={`mailto:${Data.contact.email}`}
            >
              {Data.contact.email}
            </Button>
            <Button fontSize={'sm'} colorScheme='primary' leftIcon={<MdPhone />} href={`tel:${Data.contact.phone}`}>
              {Data.contact.phone}
            </Button>
          </>
        )}
      </>
    );
  };

  const Toggle = () => {
    return (
      <>
        <IconButton onClick={onToggle} icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />} variant={'ghost'} />
      </>
    );
  };

  return (
    <Box>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
      >
        <Container maxW='7xl'>
          <Flex alignItems='center'>
            <Flex flex={{ base: 1, lg: 'auto' }} ml={{ base: -2 }} display={{ base: 'flex', lg: 'none' }}>
              <Toggle />
            </Flex>
            <Flex flex={{ base: 1 }} justify={{ base: 'center', lg: 'start' }}>
              <Logo />

              <Flex display={{ base: 'none', lg: 'flex' }} ml={10} alignItems='center'>
                <DesktopNav />
              </Flex>
            </Flex>

            <Stack flex={{ base: 1, lg: 0 }} justify={'flex-end'} direction={'row'} spacing={{ base: 2, lg: 6 }}>
              <ButtonActions />
            </Stack>
          </Flex>
        </Container>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
};

export default Navigation;
