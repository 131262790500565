import { Box, Button, Container, Flex, Heading, Image, Stack, Text } from '@chakra-ui/react';
import g1 from './assets/gallery/1.jpeg';
import g2 from './assets/gallery/2.jpeg';
import g3 from './assets/gallery/3.jpeg';
import g4 from './assets/gallery/4.jpeg';
import g5 from './assets/gallery/5.jpeg';
import g6 from './assets/gallery/6.jpeg';
import g7 from './assets/gallery/7.jpeg';
import g8 from './assets/gallery/8.jpeg';
import g9 from './assets/gallery/9.jpeg';
import g10 from './assets/puncte/bodrogului-18.jpeg';
import g11 from './assets/puncte/bodrogului-20.png';
import g12 from './assets/puncte/validimirescu.jpeg';
import { MdLocationPin } from 'react-icons/md';
import { NavLink } from 'react-router-dom';

const images = [g1, g2, g3, g4, g5, g6, g7, g8, g9, g10, g11, g12];

const Gallery = () => {
  return (
    <>
      {images.map((image, index) => {
        return (
          <Box p='2px' key={index}>
            <Image boxSize={{ base: '100%', md: '250px' }} objectFit='cover' src={image} />
          </Box>
        );
      })}
    </>
  );
};
const Home = () => {
  return (
    <>
      <Box bg='primary.50'>
        <Container maxW={'7xl'}>
          <Stack textAlign={'center'} align={'center'} spacing={{ base: 8, md: 10 }} py={{ base: 20, md: 28 }}>
            <Heading fontWeight={700} fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }} lineHeight={'110%'}>
              Cauti o statie
              <Text as={'span'} color={'primary.500'}>
                {' '}
                ITP ?{' '}
              </Text>
            </Heading>

            <Heading as='h3' mt='10px!important' fontWeight={400} fontSize={{ base: 'xl', sm: 'xl', md: '2xl' }} lineHeight={'110%'}>
              Noi iti oferim servicii la cele mai inalte standarde.
            </Heading>
            <Text maxW={'4xl'} fontSize='xl' fontWeight={300}>
              ITP Truck Arad iti sta la dispozitie cu verificari pentru ITP orice tip de autovehicule, autoturisme si autoutilitare. Lucrarile de acest tip se
              efectueaza de catre specialistii nostri calificati pe standuri ultramoderne ce permit efectuarea operatiilor prevazute de legislatie. Toate aceste
              tipuri de verificari se efectueaza sub asistenta si autorizarea Registrului Auto Roman (R.A.R.).
            </Text>
            <Text maxW={'4xl'} fontSize='xl' fontWeight={300}>
              ITP-ul este o verificare tehnica periodica, obligatorie, a autovehiculului. Aceasta presupune verificarea mai multor parametri care tin de
              poluare, siguranta si estetica sub asistenta si autorizarea Registrului Auto Roman (R.A.R.).
            </Text>
            <Text maxW={'4xl'} fontSize='xl' fontWeight={300}>
              Venind in completarea lucrarilor de service, serviciul de inspectie tehnica periodica (ITP), se vrea a fi un plus de valoare in castigarea
              increderii clientilor nostri. in eventualitatea constatarii unor defectiuni pe linia de ITP, acestea pot fi remediate de catre departamentul
              nostru de service, urmand ca dupa aceea sa se reinspecteze autovehiculul si sa se acorde ITP-ul, castigul clientului fiind economia de timp si
              garantia lucrarilor efectuate.
            </Text>

            {/* <Box pt={8}>
              <Button leftIcon={<MdLocationPin />} colorScheme='primary' as={NavLink} to='/contact'>
                Puncte de lucru
              </Button>
            </Box> */}
          </Stack>
        </Container>
      </Box>

      <Box bg='primary.100'>
        <Container maxW={'5xl'} py={{ base: 5, md: 20 }}>
          <Heading as='h2' size='2xl' align='center' fontWeight={400} color='primary.700'>
            Despre noi
          </Heading>
          <Text py={8} maxW={'2xl'} align='center' mx='auto' fontSize='xl' fontWeight={300}>
            Firma ITP Truck Arad are ca obiect de activitate efectuarea de Inspectii Tehnice Periodice (ITP) pentru autoturisme, microbuze, autorulote,
            automobile utilitare, mixte, specializate, special cu masa totala maxima autorizata pana la sau peste 3.5t , tractoare rutiere, remorci, semiremorci
            si rulote cu si fara sistem de pana la sau peste 3.5 tone.
          </Text>
          <Flex maxW='5xl' flexWrap='wrap' justifyContent='center'></Flex>
        </Container>
      </Box>

      <Box>
        <Container maxW={'5xl'} py={{ base: 5, md: 20 }}>
          <Heading as='h2' size='2xl' align='center' fontWeight={400}>
            Galerie imagini
          </Heading>
          <Text size='md' align='center' fontWeight={400} pb={8}>
            cateva imagini mai reprezentative
          </Text>
          <Flex maxW='5xl' flexWrap='wrap' justifyContent='center'>
            <Gallery />
          </Flex>
        </Container>
      </Box>
    </>
  );
};

export default Home;
