import {
  Box,
  Container,
  Link,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react';
import { FaFacebook } from 'react-icons/fa';
import { Data } from './../Data';

const Footer = () => {
  return (
    <Box
      bg={useColorModeValue('secondary.50', 'gray.900')}
      color={useColorModeValue('secondary.500', 'gray.200')}
    >
      <Container
        as={Stack}
        maxW='7xl'
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
      >
        <Text fontSize={'sm'} align={{ base: 'center', sm: 'left' }}>
          &copy; Copyright {new Date().getFullYear()} {Data.copyright}
        </Text>
        <Stack direction={'row'} spacing={6}>
          <SocialButton label={'Facebook'} href={Data.contact.facebook}>
            <FaFacebook />
          </SocialButton>
        </Stack>
      </Container>
    </Box>
  );
};

const SocialButton = ({ children, label, href }) => {
  return (
    <Box
      as={Link}
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      fontSize='xl'
      isExternal
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </Box>
  );
};

export default Footer;
