import { Box, Button, Container, Flex, Image, SimpleGrid, Stack, Text, List, ListItem, ListIcon } from '@chakra-ui/react';
import { Fragment } from 'react';
import { puncteLucru } from './Data';
import Seo from './components/Seo';
import Titlebar from './components/Titlebar';
import { BiTimeFive, BiPhone } from 'react-icons/bi';

const Puncte = () => {
  return (
    <>
      <Seo siteTitle='Puncte de lucru' canonical='puncte' />
      <Titlebar title='Puncte de lucru' />
      <Box pt={{ base: 5, md: 10 }} pb={24}>
        <Container maxW='5xl' my={{ base: 10, md: 15 }}>
          <Box p={{ base: 5, sm: 10, md: 10, lg: 16 }} mb={{ base: 3, md: 24 }}>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
              {puncteLucru.map((item, index) => {
                return (
                  <Fragment key={index}>
                    <PunctItem name={item.name} address={item.address} image={item.image} phone={item.phone} />
                  </Fragment>
                );
              })}
            </SimpleGrid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

const PunctItem = ({ address, image, phone, name }) => {
  return (
    <Stack align='center'>
      <Box mb={6} textAlign='center'>
        <Flex w='150px' h='150px' align={'center'} justify={'center'} color={'white'} rounded={'full'} bg={'gray.100'} mb={1}>
          <Image borderRadius='full' boxSize='150px' width='150px' height='150px' src={image} alt={name} />
        </Flex>
        <Text fontWeight={600} fontSize='lg' py={4}>
          {name}
        </Text>
        <Text color={'gray.600'}>{address}</Text>
        <Button colorScheme='primary' variant='ghost' href={`tel:${phone}`} as={'a'} leftIcon={<BiPhone />}>
          {phone}
        </Button>
      </Box>

      <Box textAlign='center'>
        <Text pb={3}>Orar:</Text>
        <List spacing={3} textAlign='center'>
          <ListItem>
            <ListIcon as={BiTimeFive} color='primary.500' />
            Luni-Vineri 8:00 - 20:00
          </ListItem>
          <ListItem>
            <ListIcon as={BiTimeFive} color='primary.500' />
            Sâmbătă 8:00 - 14:00
          </ListItem>
        </List>
      </Box>
    </Stack>
  );
};

export default Puncte;
