import {
  Container,
  Table,
  TableCaption,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import Seo from './components/Seo';
import Titlebar from './components/Titlebar';

const prices = [
  {
    categorie: 'Autoturism',
    itp: 110,
    siguranta: 30,
    poluare: 20,
    altele: 10,
  },
  {
    categorie:
      'Microbuz, autorulota, automobile utilitare, mixte, specializate, special cu masa totala maxima autorizata pana la 3.5t inclusiv',
    itp: 140,
    siguranta: 30,
    poluare: 30,
    altele: 20,
  },
  {
    categorie: 'Autoturism 4X4, autoutilitara 4X4',
    itp: 110,
    siguranta: 30,
    poluare: 20,
    altele: 10,
  },
  {
    categorie: 'Autovehicul cu asa totala maxima autorizata peste 3,5t',
    itp: 220,
    siguranta: 50,
    poluare: 30,
    altele: 20,
  },
  {
    categorie: 'Tractor rutier',
    itp: 150,
    siguranta: 30,
    poluare: 20,
    altele: 20,
  },
  {
    categorie: 'Remorca si rulota fara sistem de franare',
    itp: 80,
    siguranta: 20,
    poluare: 0,
    altele: 10,
  },
  {
    categorie:
      'Remorca, semiremorca si rulota cu sistem de franare avand masa maxima autorizata pana la 3.5t inclusiv',
    itp: 120,
    siguranta: 20,
    poluare: 0,
    altele: 10,
  },
  {
    categorie:
      'Remorca, semiremorca  cu sistem de franare avand masa maxima autorizata peste  3.5t',
    itp: 210,
    siguranta: 50,
    poluare: 30,
    altele: 20,
  },
];
const TableHead = () => {
  return (
    <>
      <Tr>
        <Th fontSize='md'>Categorie vehicul</Th>
        <Th fontSize='md' isNumeric>
          ITP
        </Th>
        <Th fontSize='md' isNumeric>
          reverificare siguranta
        </Th>
        <Th fontSize='md' isNumeric>
          reverificare poluare
        </Th>
        <Th fontSize='md' isNumeric>
          reverificare altele
        </Th>
      </Tr>
    </>
  );
};
const Items = () => {
  return (
    <>
      {prices.map((item, index) => {
        return (
          <Tr key={index}>
            <Td>{item.categorie}</Td>
            <Td isNumeric>{item.itp}</Td>
            <Td isNumeric>{item.siguranta}</Td>
            <Td isNumeric>{item.poluare}</Td>
            <Td isNumeric>{item.altele}</Td>
          </Tr>
        );
      })}
    </>
  );
};
const Price = () => {
  return (
    <>
      <Seo siteTitle='Preturi' canonical='preturi' />
      <Titlebar title='Preturi' />
      <Container maxW={'5xl'} my={20}>
        <Table
          variant='striped'
          colorScheme='secondary'
          size='md'
          className='responsiveTable'
        >
          <TableCaption color='primary.700'>
            * Tarifele sunt exprimate in RON.
          </TableCaption>
          <Thead>
            <TableHead />
          </Thead>
          <Tbody>{<Items />}</Tbody>
        </Table>
      </Container>
    </>
  );
};

export default Price;
